<template>
  <div class="headdiv">
    <!--    标题-->
    <div style="color: red;font-weight: bolder;font-size: 35px;text-align: center;margin-top: 2vh;height: 4vh">
      康定1号隧道进口有毒有害气体监测
    </div>
<!--    表头-->
    <div style="display: flex;margin-top: 6vh">
      <div style="width: 20%;height: 11vh;border: 5px solid #FF0000;font-size: 35px;font-weight: bolder;color: #FF0000;text-align: center;line-height: 11vh;padding-right: 2vh">
        安装位置
      </div>
      <div style="width: 22%;height: 11vh;border: 5px solid #FF0000;font-size: 35px;font-weight: bolder;color: #FF0000;text-align: center;line-height: 11vh">
        气体类型
      </div>
      <div style="width: 20%;height: 11vh;border: 5px solid #FF0000;font-size: 35px;font-weight: bolder;color: #FF0000;text-align: center;line-height: 11vh">
        监测数值
      </div>
      <div style="width: 20%;height: 11vh;border: 5px solid #FF0000;font-size: 35px;font-weight: bolder;color: #FF0000;text-align: center;line-height: 11vh">
        监测时间
      </div>
      <div style="width: 20%;height: 11vh;border: 5px solid #FF0000;font-size: 35px;font-weight: bolder;color: #FF0000;text-align: center;line-height: 11vh;margin-right: 0.4vh">
        报警类型
      </div>
    </div>
    <!--    康定 1 号隧道有毒有害气体监测数据-->
    <div>
      <div style="color: red;font-size: 28px;font-weight: bolder;">
        <div id="dk">
          洞口
        </div>
        <div id="ec">
          掌子面
        </div>
        <div id="zzm">
          二衬
        </div>
      </div>
    </div>

    <div style="position: absolute;left: 20.5%;top: 23.3vh;color: #FF0000;font-weight: bolder;font-size: 28px;height: 75vh;overflow: hidden;width: 79.3%">
      <div v-for="item in qitiData" style="display: flex;width: 100%;justify-content: space-around;">
        <div style="width: 90%;height: 9.8vh;padding-left: 2%;text-align: center;line-height: 9.1vh;border: 5px solid #FF0000">
          {{ item.qititype }}
        </div>
        <div style="width: 90%;height: 9.8vh;text-align: center;line-height: 9.1vh;border: 5px solid #FF0000">
          {{ item.qitinum }}
        </div>
        <div style="width: 90%;height: 9.8vh;text-align: center;line-height: 9.1vh;border: 5px solid #FF0000">
          {{ item.updatetime }}
        </div>
        <div style="width: 90%;height: 9.8vh;text-align: center;;line-height: 9.1vh;border: 5px solid #FF0000">
          {{ item.things }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "kjkshow",
  inject: ['reload'],
  data() {
    return {
      qitiData: [],
      allAlign: 'center'
    }
  },
  created() {
    setTimeout(this.getKjkQitiData, 100);
  },
  methods: {
    getKjkQitiData() {
      this.axios.get('/qiticheck/getKjkQitiData', (response) => {
        this.qitiData = response.obj;
      });
    },

  },
  mounted() {
    setInterval(() => {
      this.reload();
    }, 36000000)

  }
}
</script>

<style scoped>
.headdiv {
  background-color: black;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

#dk {
  height: 9.3vh;
  border-top: 5px solid red;
  border-left: 5px solid red;
  border-right: 5px solid red;
  width: 20%;
  text-align: center;
  line-height: 9.3vh
}

#ec {
  height: 32vh;
  line-height: 32vh;
  border-top: 5px solid red;
  border-left: 5px solid red;
  border-right: 5px solid red;
  width: 20%;
  text-align: center
}
#zzm {
  height: 31.3vh;
  line-height: 31.3vh;
  border-top: 5px solid red;
  border-left: 5px solid red;
  border-right: 5px solid red;
  border-bottom: 5px solid red;
  width: 20%;
  text-align: center
}
</style>